import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import withMddBadge from '@shared/ui/badges/MddBadge/withMddBadge';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { Body, BodyLargeSemiBold, BodyMedium, } from '@components/styled/Typography';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import { getCurrentDate } from '@shared/lib/date-time';
import PortfolioGraph from '../../../components/IncomeGraph';
import PortfolioFollowersPreview from '../../../components/FollowersPreview';
import PortfolioMonthlyReturn from '../../../components/MonthlyReturn';
import PastMonthProfitDates from '../../../components/PastMonthProfitDates';
const MddBadge = withMddBadge(BodyMedium);
const IncomeBadgeMedium = withPercentageIncomeBadge(BodyMedium);
const IncomeBadgeEmphasized = withPercentageIncomeBadge(BodyLargeSemiBold);
const DEFAULT_RANGE = [
    { currency: 0, date: `${getCurrentDate()}` },
    { currency: 0, date: `${getCurrentDate()}` },
];
const RowSpaceBetween = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const WholePeriodDetailsContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'end',
    gap: props.theme.spacing_sizes.s,
}));
const WholePeriodDetailsRow = styled(ContainerRow)(props => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const ProfitGraph = styled.div(() => ({
    position: 'relative',
    width: '100%',
    height: 55,
}));
const FollowersMonthlyReturn = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const PastMonthProfitContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'start',
    gap: props.theme.spacing_sizes.s,
}));
const PastMonthProfit = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const EmptyContainer = styled.div(() => ({
    width: 376,
    height: 40,
}));
const FollowersMonthlyReturnLabel = styled(Body, { shouldForwardProp: (propName) => isPropValid(propName) })(props => ({
    textAlign: props.alignRight ? 'right' : 'left',
    lineHeight: '17px',
}));
const FollowersMonthlyReturnContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const PortfolioCardContent = ({ portfolio = undefined, incomeData = [], isLoading = false, }) => {
    var _a;
    const { t } = useTranslation();
    const userSubscribed = useMemo(() => !!(portfolio === null || portfolio === void 0 ? void 0 : portfolio.user_subscription_summary), [portfolio]);
    const monthlyReturnLabel = () => (userSubscribed ?
        t('portfolio.your_past_month_return') :
        t('portfolio.followers_avg_monthly_return'));
    return (_jsxs(_Fragment, { children: [_jsxs(RowSpaceBetween, { children: [_jsxs(PastMonthProfitContainer, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 134, height: 32, children: _jsxs(PastMonthProfit, { children: [_jsx(IncomeBadgeEmphasized, { badgeVariant: 'tinted', badgeSize: 'large', percentageIncome: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.past_month_difference) || 0 }), _jsx(Body, { children: t('portfolio.past_month') })] }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 120, height: 14, children: _jsx(PastMonthProfitDates, { startDate: `${(portfolio === null || portfolio === void 0 ? void 0 : portfolio.past_month_start_date) || 0}`, endDate: `${(portfolio === null || portfolio === void 0 ? void 0 : portfolio.past_month_end_date) || 0}` }) })] }), _jsxs(WholePeriodDetailsContainer, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 120, height: 23, children: _jsxs(WholePeriodDetailsRow, { children: [_jsx(IncomeBadgeMedium, { badgeVariant: 'plain', percentageIncome: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.overall_difference) || 0 }), _jsx(BodyMedium, { children: `${(portfolio === null || portfolio === void 0 ? void 0 : portfolio.whole_period_days_count) || 0}${t('portfolio.days_short')}.` })] }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 120, height: 23, children: _jsxs(WholePeriodDetailsRow, { children: [_jsx(MddBadge, { mdd: ((_a = portfolio === null || portfolio === void 0 ? void 0 : portfolio.maxDrawdown) === null || _a === void 0 ? void 0 : _a.yearly) || 0, badgeVariant: 'plain', badgeSize: 'medium' }), _jsx(BodyMedium, { children: t('portfolio.mdd.short') })] }) })] })] }), _jsx(ProfitGraph, { children: _jsx(PortfolioGraph, { width: 376, height: 40, colorVariant: Number((portfolio === null || portfolio === void 0 ? void 0 : portfolio.overall_difference) || 0) > 0 ?
                        'positive' :
                        'negative', data: incomeData, defaultRange: DEFAULT_RANGE }) }), _jsxs(FollowersMonthlyReturn, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 124, height: 57, children: _jsxs(FollowersMonthlyReturnContainer, { children: [_jsx(FollowersMonthlyReturnLabel, { children: t('portfolio.followers_label') }), _jsx(PortfolioFollowersPreview, { portfolio: portfolio })] }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 215, height: 57, children: _jsxs(FollowersMonthlyReturnContainer, { children: [_jsx(FollowersMonthlyReturnLabel, { alignRight: true, children: monthlyReturnLabel() }), _jsx(PortfolioMonthlyReturn, { portfolio: portfolio, userSubscribed: userSubscribed })] }) })] })] }));
};
export default PortfolioCardContent;
